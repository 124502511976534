<template>
  <section class="section" id="services">
    <div class="container">
      <h2>Популярные услуги</h2>
      <div class="row">
        <template v-for="group in services">
          <div class="col-sm-12 service-group" :class="{'col-xl-6' : !group.isFullWidth}" :key="group.id">
            <div class="service-wrapper">
              <div class="service-title">
                <img :src="require(`../assets/images/icons/${group.image}.svg`)">
                <div>{{group.title}}</div>
              </div>

              <div class="row">
                <div class="col-sm-12 service-item" :class="{'col-xl-6' : group.isFullWidth}" v-for="item in group.items">
                    <div class="service-item__wrapper">
                      <div class="service-item__title">{{item.title}}</div>
                      <div class="service-item__price">от {{item.price.toLocaleString()}} ₽</div>
                      <button class="button"
                              @click="showForm(item.title)">
                        Записаться
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Services",

  data() {
    return {
      services: [
        {
          id: 1,
          title: 'Техническое обслуживание',
          image: 'popular-1',
          isFullWidth: true,
          items: [
            {
              id: 11,
              title: 'ТО 15 000',
              price: 7410
            },
            {
              id: 12,
              title: 'ТО 30 000',
              price: 9100
            },
            {
              id: 13,
              title: 'ТО 45 000',
              price: 7410
            },
            {
              id: 14,
              title: 'ТО 60 000',
              price: 12350
            },
            {
              id: 15,
              title: 'ТО 75 000',
              price: 7410
            },
            {
              id: 16,
              title: 'ТО 90 000',
              price: 10300
            }
          ]
        },
        {
          id: 2,
          title: 'Ремонт тормозной системы',
          image: 'service-5',
          isFullWidth: false,
          items: [
            {
              id: 21,
              title: 'Замена колодок',
              price: 1250
            },
            {
              id: 22,
              title: 'Замена дисков',
              price: 2500
            },
            {
              id: 23,
              title: 'Чистка/смазка тормозных суппортов',
              price: 1250
            }
          ]
        },
        {
          id: 3,
          title: 'Популярные услуги',
          image: 'popular-3',
          isFullWidth: false,
          items: [
            {
              id: 31,
              title: 'Компьютерная диагностика',
              price: 2500
            },
            {
              id: 32,
              title: 'Проверка развал-схождения',
              price: 1750
            },
            {
              id: 33,
              title: 'Профилактика полного привода',
              price: 6300
            }
          ]
        }
      ],
      form: {
        subject: '',
        title: 'Запись на сервис',
        order_id: 6
      }
    }
  },

  methods: {
    showForm(service) {
      this.form.subject = 'Запись на сервис ' + service;
      this.$root.$refs.modal.show(this.form);
    }
  }
}
</script>
