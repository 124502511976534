<template>
  <nav class="header-nav">
    <div class="container">
      <ul class="header-nav__list">
        <li class="header-nav__item" v-for="item in navItems" :key="item.slug">
          <a :href="`/#${item.slug}`"
             v-if="item.scrollable"
             v-scroll-to="{
                el: `#${item.slug}`,
                offset: -200,
                duration: 100,
                lazy: false,
                easing: 'linear'}">
            {{ item.title }}</a>

          <a href=""
             @click.prevent="item.isVacancy ? $root.$refs.vacancy.show() : $root.$refs.modal.show(form)"
             v-else>
            {{ item.title }}</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "HeaderNav",
  data() {
    return {
      form: {
        subject: 'Запись на сервис в меню',
        title: 'Запись на сервис',
        order_id: 6,
      },
      navItems: [
        {
          title: 'Главная',
          slug: 'top',
          scrollable: true
        },
        {
          title: 'Акции',
          slug: 'offers',
          scrollable: true
        },
        {
          title: 'Популярные услуги',
          slug: 'services',
          scrollable: true
        },
        {
          title: 'Прайс-лист',
          slug: 'prices',
          scrollable: true
        },
        {
          title: 'Запись на сервис',
          slug: 'fos',
          scrollable: false
        },
        {
          title: 'Контакты',
          slug: 'contacts',
          scrollable: true
        },
        {
          title: 'Вакансии',
          slug: 'vacancy',
          scrollable: false,
          isVacancy: true
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>