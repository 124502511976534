<template>
  <section class="section" id="coupon">
    <div class="container">
      <div class="row coupon">
        <div class="col-xl-8 col-lg-7 col-sm-12">
          <div class="coupon-wrapper">
            <div class="coupon-text">
              <span>Не были у нас?</span><br>
              Для вас <span>КУПОН на 3000 руб.**</span><br>
              На обслуживание ВАШЕГО<br>HYUNDAI!
            </div>
            <div class="coupon-disclaimer">
              *Акция действует только для автомобилей HYUNDAI, приобретенных
              не в ДЦ Авангард Пискаревский, и не обслуживавшихся в нашем сервисном центре.
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-lg-5 col-sm-12">
          <div class="form-wrapper">
            <div class="form-header">Получить купон</div>
            <form @submit.prevent="sendForm">
<!--              <input class="input" v-model="form.name" placeholder="Ваше имя" name="name" required/>-->

              <phone-input v-model="form.phone"/>

              <label class="form-agreement">
                <input type="checkbox" checked required/>
                <div class="form-agreement__checkmark">
                  <i class="form-agreement__checkmark-icon"></i>
                </div>
                <div class="form-agreement__label">
                  Вы даете согласие на<br>
                  <a href="" @click.prevent="$root.$refs.policy.toggle(true)">обработку персональных данных</a>
                </div>
              </label>

              <button class="button">Получить купон</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Coupon",

  data() {
    return {
      form: {
        subject: 'Получить купон',
        title: 'Получить купон',
        order_id: 6,
        calltouch_id: 34101,
        name: 'name',
        phone: ''
      }
    }
  },

  methods: {
    async sendForm() {
      const result = await this.$root.$refs.modal.submit(this.form);
      if (result) {
        ym(55154632,'reachGoal','kupon')
      }

      this.form.phone = '';
    }
  }
}
</script>
