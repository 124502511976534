<template>
  <modal-wrapper :toggle="isShown" @hide="toggle(false)">
    <div class="container">
      <div class="policy-header">Правила обработки персональных данных</div>
      <div class="policy-text">
        <p>Настоящим Я, в соответствии с требованиями Федерального закона от 27.07.06 № 152-ФЗ «О персональных данных» даю свое согласие лично, своей волей и в своем интересе:<br>
          ● Импортеру/Дистрибьютору ‒ ООО «Хендэ Мотор СНГ» (адрес: 123112, г. Москва, Пр-Д 1-Й Красногвардейский, Д. 21, Стр. 1, ЭТАЖ/ЭТАЖ 31/32), далее ‒ Импортер,<br>
          ● Производителям – ООО «Эллада Интертрейд» (адрес: 236013 г. Калининград, ул. Магнитогорская д.4), ООО «Хендэ Мотор Мануфактуринг Pус» (197701, г. Санкт-Петербург, г. Сестрорецк, Левашовское ш., д. 20, литер А), далее – «Производитель», а также<br>
          ● Уполномоченным Дилером, Импортером/Дистрибьютором, Производителем ‒ лицам и контрагентам Дилера, Импортера и Производителя, с которыми Дилер, Импортер/ Дистрибьютор, Производитель заключают соответствующие договоры, для целей, указанных в настоящем согласии в соответствии с перечнем, указанным на https://www.hyundai.ru/contractors
        </p>
        <p>С целью:<br>
          – исполнения законодательства Российской Федерации;<br>
          – определения потребностей в производственной мощности;<br>
          – мониторинга исполнения Дилерскими сервисными центрами гарантийной политики;<br>
          – ведения истории обращения в Дилерские сервисные центры;<br>
          – проведения маркетинговых исследований в области продаж, сервиса, послепродажного обслуживания автомобилей;<br>
          – направления уведомлений, информационных сообщений, в том числе рекламного характера, рекламных материалов посредством e-mail, sms-сообщений, push-уведомлений, почтовой рассылки, телефонных звонков, и любых иных средств связи, в том числе электронных;<br>
          – обработки обращений, в том числе в службу клиентской поддержки;<br>
          – оказания услуги «помощи на дороге»;<br>
          – гарантийного обслуживания автомобилей.
        </p>

        <p>Я соглашаюсь на обработку, то есть совершение любых действий (операций) или совокупности действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с моими персональными данными, включая сбор, запись, систематизацию, накопление, хранение (в электронном виде и на бумажном носителе), уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), , блокирование, удаление, уничтожение, трансграничную передачу моих персональных данных, а именно:<br>
          – фамилия, имя, отчество (ФИО);<br>
          – ID;<br>
          – аккаунт в социальных сетях;<br>
          – контактная информация (контактный телефон, адрес электронной почты);<br>
          – информация о записи на тест-драйв;<br>
          – информация о записи на ТО;<br>
          – информация, содержащаяся в паспорте транспортного средства;
        </p>
        <p>Данное согласие действует в течение 5 лет.</p>
        <p>Настоящее согласие может быть отозвано Клиентом в любой момент в письменной форме путем направления письменного сообщения об указанном отзыве, если иное не установлено законодательством Российской Федерации. Обработка (в том числе архивное хранение) персональных данных сверх указанного срока осуществляется в соответствии с законодательством Российской Федерации.</p>
        <p>Клиент по письменному запросу имеет право на получение информации, касающейся обработки его персональных данных (в соответствии с п.7 ст. 14 Федерального закона Российской Федерации от 27.07.2006 г. № 152-ФЗ «О персональных данных»).</p>
        <p>Подтверждаю, что ознакомлен (а) с положениями Федерального закона Российской Федерации от 27.07.2006 г. № 152-ФЗ «О персональных данных», права и обязанности в области защиты персональных данных мне разъяснены и понятны.</p>
      </div>
    </div>
  </modal-wrapper>

</template>

<script>
import ModalWrapper from "@/components/ModalWrapper.vue";

export default {
  name: "Policy",
  components: { ModalWrapper },

  data() {
    return {
      isShown: false
    }
  },

  methods: {
    toggle(value) {
      this.isShown = value;
      document.body.classList.toggle('hidden-scroll');
    }
  },

  mounted() {
    this.$root.$refs.policy = this;
  }
}
</script>

<style scoped>

</style>