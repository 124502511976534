<template>
  <div>
    <header-top/>
    <Header/>
    <banner/>
    <offers/>
    <services/>
    <coupon/>
    <prices/>
    <contacts/>
    <Footer/>
    <modal/>
    <policy/>
    <lottery/>
    <vacancy/>
  </div>
</template>
<script>


import HeaderTop from "@/components/Header/HeaderTop";
import Header from '@/components/Header';
import Banner from '@/components/Banner';
import Offers from "@/components/Offers";
import Services from "@/components/Services";
import Coupon from "@/components/Coupon";
import Prices from "@/components/Prices";
import Contacts from "@/components/Contacts";
import Footer from "@/components/Footer";
import Modal from "@/components/Modal";
import Lottery from "@/components/Lottery";
import Policy from "@/components/Policy";
import PhoneInput from "@/components/PhoneInput";
import Vacancy from "@/components/Vacancy.vue";

export default {
  name: 'App',
  components: {
    Vacancy,
    HeaderTop,
    Header,
    Banner,
    Offers,
    Services,
    Coupon,
    Prices,
    Contacts,
    Footer,
    Modal,
    Lottery,
    Policy,
    PhoneInput
  },

  updated() {
    if ('ct_c' in window && 'ct_ready' in window) {
      window.ct_c(window.ct_ready(window.ct_replace_spr));
    }
  }
};
</script>

