<template>
  <div class="header-top" id="top">
    <div class="container">
      <div class="header-top__wrapper">
        <div class="header-top__item">
          <img src="../../assets/images/icons/rating.png" alt>
          <div class="header-top__title">Рекомендованный дилер Hyundai</div>
        </div>
        <div class="header-top__item">
          <img src="../../assets/images/icons/first.png" alt>
          <div class="header-top__title">Первое место в СПб по удовлетворенности клиентов*</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderTop"
}
</script>