<template>
  <section class="section" id="prices">
    <div class="container">
      <h2>Прайс-лист</h2>
    </div>

    <div class="prices-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-sm-12 prices-group">
            <div class="prices-group__item"
                 v-for="group in prices"
                 :key="group.id"
                 @click="activeItem = group.id"
                 :class="{'prices-group__item--active' : activeItem === group.id}">
              <div class="prices-group__header">
                <div class="prices-group__img">
                  <inline-svg
                    :src="require(`../assets/images/icons/${group.image}.svg`)"></inline-svg>
                </div>
                <div class="prices-group__title">{{group.title}}</div>
                <inline-svg :src="require(`../assets/images/icons/chevron-down.svg`)" class="chevron"></inline-svg>
              </div>

              <div class="prices-group__items">
                <div class="prices-item__wrapper" v-for="item in group.items" :key="item.id">
                  <div class="prices-item__title">{{item.title}}</div>
                  <div class="prices-item__price">от {{item.price.toLocaleString()}} ₽</div>
                  <button class="button"
                          @click="showForm(item.title)">
                    Записаться
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 prices-items">
            <div class="prices-item__wrapper" v-for="item in activeItems" :key="item.id">
              <div class="prices-item__title">{{item.title}}</div>
              <div class="prices-item__price">от {{item.price.toLocaleString()}} ₽</div>
              <button class="button"
                      @click="showForm(item.title)">
                Записаться
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Prices",

  data() {
    return {
      prices: [
        {
          id: 1,
          title: 'Техническое обслуживание',
          image: 'popular-1',
          items: [
            {
              id: 11,
              title: 'ТО 15 000',
              price: 7410
            },
            {
              id: 12,
              title: 'ТО 30 000',
              price: 9100
            },
            {
              id: 13,
              title: 'ТО 45 000',
              price: 7410
            },
            {
              id: 14,
              title: 'ТО 60 000',
              price: 12350
            },
            {
              id: 15,
              title: 'ТО 75 000',
              price: 7410
            },
            {
              id: 16,
              title: 'ТО 90 000',
              price: 10300
            }
          ]
        },
        {
          id: 2,
          title: 'Популярные услуги',
          image: 'popular-3',
          items: [
            {
              id: 21,
              title: 'Шиномонтаж**',
              price: 1440
            },
            {
              id: 22,
              title: 'Замена масла и масляного фильтра',
              price: 1250
            },
            {
              id: 23,
              title: 'Проверка углов развал-схождения',
              price: 1750
            },
            {
              id: 24,
              title: 'Компьютерная диагностика',
              price: 2500
            },
          ]
        },
        {
          id: 3,
          title: 'Шиномонтаж',
          image: 'popular-2',
          items: [
            {
              id: 31,
              title: 'Шиномонтаж**',
              price: 1440
            },
            {
              id: 32,
              title: 'Проверка углов развал-схождения',
              price: 1750
            },
            {
              id: 33,
              title: 'Сезонное хранение колес',
              price: 2000
            }
          ]
        },
        {
          id: 4,
          title: 'Замена эксплуатационных жидкостей',
          image: 'service-4',
          items: [
            {
              id: 41,
              title: 'Замена масла и масляного фильтра',
              price: 1250
            },
            {
              id: 42,
              title: 'Замена масла в АКПП',
              price: 2500
            },
            {
              id: 43,
              title: 'Замена тормозной жидкости',
              price: 1250
            },
            {
              id: 44,
              title: 'Замена охлаждающей жидкости',
              price: 1250
            }
          ]
        },
        {
          id: 5,
          title: 'Ремонт тормозной системы',
          image: 'service-5',
          items: [
            {
              id: 51,
              title: 'Замена тормозных колодок',
              price: 1250
            },
            {
              id: 52,
              title: 'Замена тормозных дисков',
              price: 2500
            },
            {
              id: 53,
              title: 'Чистка/смазка тормозных суппортов',
              price: 1250
            },
            {
              id: 54,
              title: 'Регулировка стояночного тормоза',
              price: 800
            }
          ]
        },
        {
          id: 6,
          title: 'Ремонт подвески',
          image: 'service-6',
          items: [
            {
              id: 61,
              title: 'Замена переднего амортизатора',
              price: 2500
            },
            {
              id: 62,
              title: 'Замена шпильки крепления колеса',
              price: 1250
            },
            {
              id: 63,
              title: 'Проверка углов развал-схождения',
              price: 1750
            }
          ]
        },
        {
          id: 7,
          title: 'Ремонт электрооборудования',
          image: 'service-7',
          items: [
            {
              id: 71,
              title: 'Регулировка фар',
              price: 750
            },
            {
              id: 72,
              title: 'Замена лампочки',
              price: 500
            },
            {
              id: 73,
              title: 'Программирование ключа',
              price: 1250
            }
          ]
        },
        {
          id: 8,
          title: 'Контрольно-диагностические операции',
          image: 'service-8',
          items: [
            {
              id: 81,
              title: 'Компьютерная диагностика',
              price: 2500
            },
            {
              id: 82,
              title: 'Диагностика кондиционера',
              price: 2500
            },
            {
              id: 83,
              title: 'Диагностика подвески (ходовой части)',
              price: 2500
            }
          ]
        },
        {
          id: 9,
          title: 'Заправка и ремонт кондиционера',
          image: 'service-9',
          items: [
            {
              id: 91,
              title: 'Заправка кондиционера',
              price: 4000
            },
          ]
        }
      ],

      activeItem: 1,

      form: {
        subject: '',
        title: 'Запись на сервис',
        order_id: 6
      }
    }
  },

  computed: {
    activeItems() {
      return this.prices.find(item => item.id === this.activeItem).items
    }
  },

  methods: {
    showForm(price) {
      this.form.subject = 'Запись на сервис ' + price;
      this.$root.$refs.modal.show(this.form);
    }
  }
}
</script>
