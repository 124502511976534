<template>
  <modal-wrapper :toggle="toggle" @hide="hide">
    <div class="lottery">
      <div class="circle" :style="`transform: rotate(-${rotateDeg}deg); text-indent: 0;`">
        <div class="circle_item"
             v-for="(item, index) in circleItems"
             :key="item.id"
             :style="getStyle(index)">
          <input type="checkbox" :value="item.title" name="present" :id="`drum-${item.id}`" :checked="item.checked">
          <label :for="`drum-${item.id}`">{{ item.title }}</label>
        </div>
      </div>
      <div class="circle__arrow"></div>
    </div>

    <div class="lottery-result" v-if="present">
      <h2>Ваш подарок: {{present}}</h2>
      <button class="button" @click="rotate">Играть ещё раз</button>
      <br>
      <button class="button" @click="showForm" v-if="!isSent">Забрать подарок</button>
    </div>
  </modal-wrapper>
</template>

<script>
import Vue from "vue";
import ModalWrapper from "@/components/ModalWrapper.vue";

export default {
  name: "Lottery",
  components: {ModalWrapper},
  data() {
    return {
      toggle: false,
      rotateDeg: 0,

      form: {
        subject: '',
        title: '',
        order_id: 1,
        lottery: true
      },

      circleItems: [
        {
          id: 2,
          title: 'Мойка «Люкс»',
          checked: false
        },
        {
          id: 3,
          title: 'Установка салонного фильтра',
          checked: false
        },
        {
          id: 5,
          title: 'Мойка двигателя',
          checked: false
        },
        {
          id: 6,
          title: 'Сертификат на ТО на 3000 руб.',
          checked: false
        },
        {
          id: 7,
          title: 'Сертификат «помощь на дороге»',
          checked: false
        },
        {
          id: 8,
          title: 'ТО в полцены и замена масла за 1р.',
          checked: false
        }
      ]
    };
  },

  computed: {
    deg() {
      return 360 / this.circleItems.length;
    },

    rotateLabel() {
      return 360 / this.circleItems.length / 2;
    },

    isGamed() {
      return Vue.$cookies.isKey('isGamed')
    },

    isSent() {
      return Vue.$cookies.isKey('isSent')
    },

    present() {
      return Vue.$cookies.isKey('present') ? Vue.$cookies.get('present') : '';
    }
  },

  methods: {
    hide() {
      this.toggle = false;
      this.rotateDeg = 0;
    },

    show() {
      this.toggle = true;

      if (!this.isGamed) {
        this.rotate();
      }
    },

    getStyle(index) {
      const value = 100 / this.circleItems.length
      const offset = value * index;

      return `--offset: ${offset}; --value: ${value}; --rotate-label: ${this.rotateLabel}`;
    },

    rotate() {
      const index = this.randomInteger(0, this.circleItems.length - 1);
      this.circleItems[index].checked = true;

      setTimeout(() => {
        this.rotateDeg = this.deg * index + (360 * 2) + this.rotateLabel;
      }, 0)

      setTimeout(() => {
        this.form.subject = 'Форма барабан, Подарок: ' + this.circleItems[index].title;
        this.form.title = 'Забрать подарок: ' + this.circleItems[index].title;
        Vue.$cookies.set('isGamed', true);
        Vue.$cookies.set('present', this.circleItems[index].title);
      }, 2000)
    },

    randomInteger(min, max) {
      return Math.round(min + Math.random() * (max - min));
    },

    showForm() {
      this.hide();
      this.$root.$refs.modal.show(this.form);
    }
  },

  mounted() {
    if (Vue.$cookies.isKey('present')) {
      this.form.subject = 'Форма барабан, Подарок: ' + Vue.$cookies.get('present');
      this.form.title = 'Забрать подарок: ' + Vue.$cookies.get('present');
    }

    this.$root.$refs.lottery = this;
  }
}
</script>

<style scoped>

</style>