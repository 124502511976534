<template>
  <modal-wrapper :toggle="toggle" @hide="hide">
    <div class="form-wrapper">
      <div class="form-header">Откликнуться</div>
      <form @submit.prevent="submitForm">
        <select class="input" v-model="form.vacancy" required>
          <option disabled selected value="">Выберите вакансию</option>
          <option v-for="vacancy in vacancies" :value="vacancy">{{ vacancy }}</option>
        </select>
        <input class="input" v-model="form.name" placeholder="Ваше имя" name="name" required/>
        <phone-input v-model="form.phone"/>
        <label class="form-agreement">
          <input type="checkbox" checked required/>
          <div class="form-agreement__checkmark">
            <i class="form-agreement__checkmark-icon"></i>
          </div>
          <div class="form-agreement__label">
            Вы даете согласие на<br>
            <a href="" @click.prevent="$root.$refs.policy.toggle(true)">обработку персональных данных</a>
          </div>
        </label>

        <button class="button">Отправить</button>
      </form>
    </div>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from "@/components/ModalWrapper.vue";

export default {
  name: "Vacancy",
  components: {ModalWrapper},

  data() {
    return {
      toggle: false,

      form: {
        subject: 'Отклик на вакансию',
        order_id: 20,
        calltouch_id: 34101,
        name: '',
        phone: '',
        vacancy: ''
      },

      vacancies: ['Механик', 'Ученик механика']
    };
  },
  methods: {
    hide() {
      this.toggle = false;
      this.form.phone = this.form.name = this.form.vacancy = this.form.subject = '';
    },

    show() {
      this.toggle = true;
    },

    async submitForm() {
      await this.$root.$refs.modal.submit(this.form);
      this.hide();
    }
  },

  mounted() {
    this.$root.$refs.vacancy = this;
  }
}
</script>

<style scoped>

</style>