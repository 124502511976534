<template>
  <input v-maska="'+7 (###) ###-##-##'"
         class="input"
         @focus="focus = true"
         @input="onInput($event)"
         @blur="focus = false"
         :placeholder="focus ? '' : 'Номер телефона'"
         name="phone"
         minlength="18"
         required>
</template>

<script>
import { maska } from 'maska';
export default {
  directives: { maska },

  props: ['value'],

  data() {
    return {
      focus: false,
    }
  },

  methods: {
    onInput($event) {
      if ($event.target.value[0] === '8') {
        $event.target.value = '+7';
      }
      this.$emit('input', $event.target.value);
    }
  }
}
</script>

<style scoped>

</style>