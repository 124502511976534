<template>
  <section class="section" id="offers">
    <div class="container">
      <h2 style="color: red;">Весенний сервис в Авангард</h2>
      <div class="row">
        <div class="col-md-6 col-sm-12 col-lg-4" v-for="offer in offers" :key="offer.id">
          <div class="offer-wrapper">
            <img :src="require(`../assets/images/${offer.image}`)">
            <div v-html="offer.title" class="offer-title"></div>
            <button class="button"
                    @click="showForm(offer.title)">
              Записаться на сервис
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Offers",
  data() {
    return {
      offers: [
        {
          id: 4,
          title: 'Очистка и обработка кузова',
          image: 'action-7.jpg'
        },

        {
          id: 2,
          title: 'Смазка замков',
          image: 'action-2.jpg'
        },

        {
          id: 3,
          title: 'Мойка кузова и салона',
          image: 'action-3.jpg'
        },

        {
          id: 1,
          title: 'Смазка резиновых уплотнителей',
          image: 'action-1.jpg'
        },

        // {
        //   id: 4,
        //   title: 'Восстановление хромированных элементов – 2&nbsp;000&nbsp;₽ при совмещении с ТО',
        //   image: 'action-4.jpg'
        // },

        {
          id: 5,
          title: 'Диагностика всех систем',
          image: 'action-5.jpg'
        },

        {
          id: 6,
          title: 'Антидождь на стекла',
          image: 'action-6.jpg'
        },
      ],
      form: {
        subject: '',
        title: 'Запись на сервис',
        order_id: 6
      }
    }
  },

  methods: {
    showForm(offer) {
      this.form.subject = 'Запись на сервис ' + offer;
      this.$root.$refs.modal.show(this.form);
    }
  }
}
</script>
