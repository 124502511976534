<template>
  <transition name="fade">
    <div class="modal-outer" v-if="toggle" @click="$emit('hide')">
      <div class="modal-floatbox" @click.stop>
        <div class="modal-close" @click="$emit('hide')">
          <inline-svg :src="require(`../assets/images/icons/close.svg`)"></inline-svg>
        </div>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalWrapper",

  props: {
    toggle: {
      default: false,
      type: Boolean,
      required: true
    }
  },

  emits: ['hide']
}
</script>

<style scoped>

</style>