<template>
  <modal-wrapper :toggle="toggle" @hide="hide">
    <div class="modal-header" v-if="typeof response !== 'undefined'" v-html="response"></div>

    <template v-else>
      <div class="form-wrapper">
        <div class="form-header">{{form.title}}</div>
        <form @submit.prevent="submit(form)">
          <!--              <input class="input" v-model="form.name" placeholder="Ваше имя" name="name" required/>-->
          <phone-input v-model="form.phone"/>
          <label class="form-agreement">
            <input type="checkbox" checked required/>
            <div class="form-agreement__checkmark">
              <i class="form-agreement__checkmark-icon"></i>
            </div>
            <div class="form-agreement__label">
              Вы даете согласие на<br>
              <a href="" @click.prevent="$root.$refs.policy.toggle(true)">обработку персональных данных</a>
            </div>
          </label>

          <button class="button">Отправить</button>
        </form>
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
import axios from 'axios';
import Vue from "vue";
import ModalWrapper from "@/components/ModalWrapper.vue";

export default {
  components: { ModalWrapper },
  data() {
    return {
      toggle: false,
      response: undefined,

      form: {
        subject: '',
        title: '',
        order_id: 1,
        calltouch_id: 34101,
        name: 'name',
        phone: '',
        lottery: false
      }
    };
  },
  methods: {
    hide() {
      this.toggle = false;
      this.form.phone = this.form.subject = this.form.title = '';
      this.form.lottery = false;
    },

    show(data, response = undefined) {
      this.response = response;

      if (data) {
        this.form.subject = data.subject;
        this.form.title = data.title;
        this.form.order_id = data.order_id;
        this.form.lottery = data.lottery || false;
      }

      this.toggle = true;
    },

    async submit(formData) {
      let isSuccess = false;
      try {
        formData.url = window.location.href;
        formData.session_id = window.call_value ? window.call_value : null;

        if (process.env.NODE_ENV === 'development') {
          console.log(formData)
        }

        if (formData.phone.length === null || formData.phone.length < 18) {
          return this.show(null, 'Неверно введён номер телефона');
        }

        const url = process.env.NODE_ENV === 'production'
            ? 'https://orders.avangard-mb.ru/api/order'
            : 'https://test-orders.avangard-mb.ru/api/order';

        const response = await axios.post(url, formData);

        ym(55154632,'reachGoal','forma_otpravlena');

        if (formData.lottery) {
          Vue.$cookies.set('isSent', true);
          ym(55154632,'reachGoal','koleso_fortuna')
        }

        this.hide();

        const message = await response?.data?.data?.message ||
            response?.data?.message ||
            response?.data?.data ||
            'Мы получили Ваше сообщение и перезвоним в ближайшее время.';

        this.show(null, message);
        isSuccess = true;

      } catch (error) {
        let msg = error?.response?.data?.error?.msg ||
          error?.response?.data ||
          error?.message ||
          error;

        if (typeof msg === 'object') {
          msg = this.flatObject(msg).join('<br><br>');
        }

        if (msg === 'Network Error') {
          return this.show(null, 'Ошибка сети. Проверьте доступ к интернету.');
        }

        this.show(null, msg ? 'Возникли ошибки: <br><br>' + msg : 'Произошла неизвестная ошибка');
      }

      return isSuccess;
    },

    flatObject(item) {
      if (typeof item === 'string')
        return item;
      if (typeof item === 'object')
        return Object.values(item).flatMap(this.flatObject);
    }
  },
  mounted() {
    this.$root.$refs.modal = this;
  }
};
</script>
