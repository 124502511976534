<template>
  <footer>
    <div class="container">
      <div class="row footer-row">
        <div class="col-sm-12">
          <div class="footer-copyright">
            © {{ currentYear }} Авангард Пискаревский
          </div>
        </div>
      </div>

      <div class="row footer-row">
        <div class="col-sm-12 col-md-10">
          <div class="footer-disclaimer">
            * – Данная информация не является офертой, определяемой положениями статей 435, 437 Гражданского Кодекса РФ<br>
            ** - Цена действительна при условии хранения колес в сервисном центре Авангард Пискаревский<br>
            Скидки по различным акциям не суммируются.<br><br>
            АО «АВАНГАРД Пискарёвский»<br>
            ИНН 7806303194<br>
            ОГРН 1047855056556
          </div>
        </div>

        <div class="col-sm-12 col-md-2">
          <div class="footer-social">
           <div class="footer-social__item" v-for="social in socials" :key="social.id">
              <a :href="social.url" target="_blank">
                <inline-svg :src="require(`../assets/images/icons/${social.title}.svg`)"></inline-svg>
              </a>
           </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',

  data() {
    return {
      socials: [
        // {
        //   id: 1,
        //   title: 'facebook',
        //   url: 'https://www.facebook.com/hyundai.avangard'
        // },

        {
          id: 2,
          title: 'vkontakte',
          url: 'https://vk.com/hyundai_avangard'
        },

        // {
        //   id: 3,
        //   title: 'instagram',
        //   url: 'https://www.instagram.com/hyundai_avangard_piskarevsky/'
        // }
      ]
    }
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>
