import Vue from 'vue'
import App from './App.vue'
import VueScrollTo from 'vue-scrollto';
import InlineSvg from 'vue-inline-svg';
import VueCookies from 'vue-cookies-reactive';
import PhoneInput from './components/PhoneInput';

import './assets/scss/styles.scss';

require('./assets/js/jquery')

Vue.config.productionTip = false;
Vue.use(VueScrollTo);
Vue.use(VueCookies);
Vue.component('PhoneInput', PhoneInput);
Vue.component('InlineSvg', InlineSvg);

Vue.$cookies.config('1d')


new Vue({
  render: h => h(App),
}).$mount('#app')






